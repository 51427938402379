.weather {
  width: 350px;
  border-radius: 6px;
  box-shadow: 10px -2px 20px 2px rgb(0 0 0 / 30%);
  color: #fff;
  background-color: #333;
  margin: 20px auto 0 auto;
  padding: 0 20px 20px 20px;
}

.top,
.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.city {
  font-weight: 600;
  font-size: 18px;
  line-height: 1;
  margin: 0;
  letter-spacing: 1px;
}

.weather-description {
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  margin: 0;
}

.weather-icon {
  width: 100px;
}

.temperature {
  font-weight: 600;
  font-size: 70px;
  width: auto;
  letter-spacing: -5px;
  margin: 10px 0;
}

.details {
  width: 100%;
  padding-left: 20px;
}

.parameter-row {
  display: flex;
  justify-content: space-between;
}

.parameter-label {
  text-align: left;
  font-weight: 400;
  font-size: 12px;
}

.parameter-value {
  text-align: right;
  font-weight: 600;
  font-size: 12px;
}

.parameter-label.top {
  border-bottom: 1px solid #fff;
}

.details-title {
  text-decoration: underline;

}
